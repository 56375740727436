(function ($) {
    $(document).ready(function () {
        "use strict";

        // TAB
        $(".tab-nav li").on("click", function (e) {
            $(".tab-item").hide();
            $(".tab-nav li").removeClass("active");
            $(this).addClass("active");
            var selected_tab = $(this).find("a").attr("href");
            $(selected_tab).stop().show();
            return false;
        });

        // SEARCH
        $(".navbar .search-button, .sticky-navbar .search-button").on(
            "click",
            function (e) {
                $(".search-box").addClass("active");
                $("body").addClass("overflow");
            }
        );
        $(".search-box .close-button").on("click", function (e) {
            $(".search-box").removeClass("active");
            $("body").removeClass("overflow");
        });
    });
    // END DOCUMENT READY



    // SVG PROGRESS
    jQuery(window).on("load", function () {
        var progressPath = document.querySelector(".scrollup path");
        var pathLength = progressPath.getTotalLength();

        progressPath.style.transition = progressPath.style.WebkitTransition =
            "none";
        progressPath.style.strokeDasharray = pathLength + " " + pathLength;
        progressPath.style.strokeDashoffset = pathLength;
        progressPath.getBoundingClientRect();
        progressPath.style.transition = progressPath.style.WebkitTransition =
            "stroke-dashoffset 10ms linear";

        var updateProgress = function () {
            var scroll = jQuery(window).scrollTop();
            var height = jQuery(document).height() - jQuery(window).height();
            var progress = pathLength - (scroll * pathLength) / height;
            progressPath.style.strokeDashoffset = progress;
        };

        updateProgress();

        jQuery(window).scroll(updateProgress);
    });

    // GO TO TOP
    $(window).scroll(function () {
        if ($(this).scrollTop() > 400) {
            $(".scrollup").fadeIn();
        } else {
            $(".scrollup").fadeOut();
        }
    });

    $(".scrollup").on("click", function (e) {
        $("html, body").animate({
            scrollTop: 0,
        },
            600
        );
        return false;
    });
    $(".go-to-top").on("click", function (e) {
        $("html, body").animate({
            scrollTop: 0,
        },
            600
        );
        return false;
    });

    // DATA BACKGROUND IMAGE
    var pageSection = $("*");
    pageSection.each(function (indx) {
        if ($(this).attr("data-background")) {
            $(this).css(
                "background",
                "url(" + $(this).data("background") + ")"
            );
        }
    });

    $('#c-message').on('input', function () {
        this.style.height = 'auto';

        this.style.height =
            (this.scrollHeight) + 'px';
    });


    setTimeout(function () {
        if (!Cookies.get('modalShown')) {
            $("#SubscribeModal").modal('show');
            Cookies.set('modalShown', true);
        } else {
            console.log('subscribe modal won\'t show again as it\'s shown before.');
        }
    }, 100000);


    //Fullscreen search underline animation
    const wrapper = document.querySelector(".input-wrapper"),
        textInput = document.querySelector("input#search");
    textInput.addEventListener("keyup", (event) => {
        wrapper.setAttribute("data-text", event.target.value);
    });

    if (document.querySelector(".slider-offbeat")) {
        var offbeat = new Swiper(".slider-presta", {
            slidesPerView: 2.5,
            spaceBetween: 10,
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
            },
            autoplay: {
                delay: 4000,
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1.5,
                    spaceBetween: 10
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 1.5,
                    spaceBetween: 10
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 1.5,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 1.5,
                    spaceBetween: 10
                },
                1024: {
                    slidesPerView: 2.5,
                    spaceBetween: 15
                }
            }
        });
    }

    var mySwiper = new Swiper(".news-slide", {
        spaceBetween: 10,
        slidesPerView: 4.5,
        loop: true,
        autoplay: {
            delay: 3000,
        },
        // pagination: {
        //     el: ".swiper-pagination",
        //     clickable: true,
        // },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 15
            },
            1024: {
                slidesPerView: 3.5,
                spaceBetween: 15
            }
        }
    });


    const not_active_slide_scale_value = 0.76;
        const not_active_slide_opacity_value = 0.9;

        var swiper = new Swiper("[swiper_scale_active]", {
            slidesPerView: 1.5,
            parallax: true,
            breakpoints: {
                320: {
                    speed: 900,
                    slidesPerView: 1.1
                },
                640: {
                    speed: 1400
                }
            },
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            centeredSlides: true,
            loop: true,
            slideToClickedSlide: true,
            spaceBetween: 0,
            grabCursor: true,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: '[btn_group] [next]',
                prevEl: '[btn_group] [prev]',
            },
            effect: "creative",
            creativeEffect: {
                limitProgress: 2,
                prev: {
                    opacity: not_active_slide_opacity_value,
                    scale: not_active_slide_scale_value,
                    translate: ["-90%", 0, 0],
                },
                next: {
                    opacity: not_active_slide_opacity_value,
                    scale: not_active_slide_scale_value,
                    translate: ["90%", 0, 0],
                },
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            }
        });

    $('.card').on('mouseenter', function (e) {
        let x = e.pageX - $(this).offset().left;
        let y = e.pageY - $(this).offset().top;
        $(this).find('span').css({
            top: y,
            left: x
        });
    });

    $('.card').on('mouseout', function (e) {
        let x = e.pageX - $(this).offset().left;
        let y = e.pageY - $(this).offset().top;
        $(this).find('span').css({
            top: y,
            left: x
        });
    });

    // Add minus icon for collapse element which is open by default
    $(".collapse.show").each(function () {
        $(this).prev(".card-header").addClass("highlight");
    });

    // Highlight open collapsed element 
    $(".card-header .btn").click(function () {
        $(".card-header").not($(this).parents()).removeClass("highlight");
        $(this).parents(".card-header").toggleClass("highlight");
    });

    /* Mobile Navigation
          -------------------------------------------------------*/
    var $sidenav = $("#sidenav"),
        $mainContainer = $("#main-container"),
        $navIconToggle = $(".nav-icon-toggle"),
        $navHolder = $(".nav__holder"),
        $contentOverlay = $(".content-overlay"),
        $htmlContainer = $("html"),
        $sidenavCloseButton = $("#sidenav__close-button");

    $navIconToggle.on("click", function (e) {
        e.stopPropagation();
        $(this).toggleClass("nav-icon-toggle--is-open");
        $sidenav.toggleClass("sidenav--is-open");
        $contentOverlay.toggleClass("content-overlay--is-visible");
        // $htmlContainer.toggleClass('oh');
    });

    function resetNav() {
        $navIconToggle.removeClass("nav-icon-toggle--is-open");
        $sidenav.removeClass("sidenav--is-open");
        $contentOverlay.removeClass("content-overlay--is-visible");
        // $htmlContainer.removeClass('oh');
    }

    function hideSidenav() {
        if (minWidth(992)) {
            resetNav();
            setTimeout(megaMenu, 500);
        }
    }

    $contentOverlay.on("click", function () {
        resetNav();
    });

    $sidenavCloseButton.on("click", function () {
        resetNav();
    });

    var $dropdownTrigger = $(".nav__dropdown-trigger"),
        $navDropdownMenu = $(".nav__dropdown-menu"),
        $navDropdown = $(".nav__dropdown");

    if ($("html").hasClass("mobile")) {
        $("body").on("click", function () {
            $navDropdownMenu.addClass("hide-dropdown");
        });

        $navDropdown.on("click", "> a", function (e) {
            e.preventDefault();
        });

        $navDropdown.on("click", function (e) {
            e.stopPropagation();
            $navDropdownMenu.removeClass("hide-dropdown");
        });
    }

    /* Sidenav Menu
    -------------------------------------------------------*/
    $(".sidenav__menu-toggle").on("click", function (e) {
        e.preventDefault();

        var $this = $(this);

        $this.parent().siblings().removeClass("sidenav__menu--is-open");
        $this.parent().siblings().find("li").removeClass("sidenav__menu--is-open");
        $this.parent().find("li").removeClass("sidenav__menu--is-open");
        $this.parent().toggleClass("sidenav__menu--is-open");

        if ($this.next().hasClass("show")) {
            $this.next().removeClass("show").slideUp(350);
        } else {
            $this.parent().parent().find("li .sidenav__menu-dropdown").removeClass("show").slideUp(350);
            $this.next().toggleClass("show").slideToggle(350);
        }
    });

    // LAZYSIZES BACKGROUND:
    document.addEventListener("lazybeforeunveil", function (e) {
        var bg = e.target.getAttribute("data-bg");
        if (bg) {
            e.target.style.backgroundImage = "url(" + bg + ")";
        }
    });
})(jQuery);

